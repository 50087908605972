var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.shouldShow)?_c('div',[(_vm.$route.name === _vm.$constants.ROUTE_NAMES.DSTV_INDEX)?_c('v-alert',{staticClass:"v-alert--flat mb-0",attrs:{"color":"info","outlined":_vm.$vuetify.breakpoint.mdAndDown,"prominent":_vm.$vuetify.breakpoint.lgAndUp,"text":""}},[_c('v-row',[(_vm.priceLockActive)?_c('v-col',[_vm._v(" DStv increased the price of DStv subscriptions from the 1"),_c('sup',[_vm._v("st")]),_vm._v(" of March 2025. You can activate the "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                        name: _vm.$constants.ROUTE_NAMES
                            .FAQ_MARCH_2025_PRICE_UPDATE,
                    }}},[_vm._v("price lock 🔐 feature")]),_vm._v(" and continue the paying the old price by ALWAYS renewing your subscription before it expires. "),_c('b',[_vm._v("Once your DStv subscription expires you will lose the price lock feature forever.")])],1):_c('v-col',[_vm._v(" DStv will increase the price of DStv subscriptions from the 1"),_c('sup',[_vm._v("st")]),_vm._v(" of May 2024. You can activate the "),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{
                        name: _vm.$constants.ROUTE_NAMES
                            .FAQ_MARCH_2025_PRICE_UPDATE,
                    }}},[_vm._v("price lock 🔐 feature")]),_vm._v(" and continue the paying the old price by ALWAYS renewing your subscription before it expires. "),_c('b',[_vm._v("Once your DStv subscription expires you will lose the price lock feature forever.")])],1)],1)],1):(_vm.canInstallApp)?_c('install-alert'):(!_vm.user)?_c('auth-alert'):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }